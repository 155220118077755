@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');
  
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* TEXT STYLES */
a {
  font-weight: 700;
  line-height: 1.5;
}

p {
  font-family: 'EB Garamond', ui-serif, Georgia, 'Cambria', 'Times New Roman', Times, serif;
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 0.75rem;
}

.p-ingress {
  font-size: 1.75rem;
  line-height: 1.25;
}

/* DESKTOP STYLES */
@media (min-width: 768px) {
  p {
    margin-bottom: 1.5rem;
  }
}