@import url(//hello.myfonts.net/count/3cbd4e);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap);
@font-face {
  font-family: 'TeeFranklin';
  font-style: medium;
  font-weight: 500;
  font-display: swap;
  src: url(/static/media/font.0483540e.woff2) format('woff2');
}

body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* TEXT STYLES */
a {
  font-weight: 700;
  line-height: 1.5;
}

p {
  font-family: 'EB Garamond', ui-serif, Georgia, 'Cambria', 'Times New Roman', Times, serif;
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 0.75rem;
}

.p-ingress {
  font-size: 1.75rem;
  line-height: 1.25;
}

/* DESKTOP STYLES */
@media (min-width: 768px) {
  p {
    margin-bottom: 1.5rem;
  }
}
